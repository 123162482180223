.tooltip-directive-arrow-down,
.tooltip-directive-container {
  position: absolute;
  z-index: -10000;
  opacity: 0;
  transition: 0.3s;
  &.shown {
    opacity: 1;
    z-index: 10000;
  }
}

.tooltip-directive-container {
  max-width: calc(var(--screen-width) * 0.65);
  background: var(--surface);
  border-radius: 10px;
  padding: 10px 15px;
  font-size: var(--base-text);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.tooltip-directive-arrow-down {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 8px 0 8px;
  border-color: var(--surface) transparent transparent transparent;
  &.secondary-gradient {
    border-color: #665AB2 transparent transparent transparent;
  }
}
