span.alert-button-inner {
  font-family: var(--title-font);
  font-weight: bold;
}

.alert-button.sc-ion-alert-md {
  &.text-primary {
    color: var(--primary);
  }
  &.text-label {
    color: var(--label);
  }
  &.capitalize {
    text-transform: capitalize;
  }
}