.border-round-modal {
  --border-radius: 15px;
}

ion-modal {
  ion-content {
    --background: var(--surface);
  }
}

.modal-bg-bg {
  ion-content {
    --background: var(--bg);
  }
}

.bottom-sheet-modal {
  --width: var(--screen-width);
}

.full-height-modal {
  --height: 100%;
}
