.test-buttons-container {
  position: fixed;
  z-index: 1000;
  background: var(--primary);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0,0,0,.25);
  transition: 0.3s;
  &.vertical {
    flex-direction: column;
    right: 10px;
    top: 0;
    border-radius: 0 0 5px 5px;
    .bottom {
      ion-icon { transform: rotate(90deg); }
    }
    &.hidden {
      top: -600px;
      .bottom {
        ion-icon { transform: rotate(-90deg); }
      }
    }
  }
  &.horizontal {
    flex-direction: row;
    top: 100px;
    left: 0;
    border-radius: 0 5px 5px 0;
    &.hidden {
      left: -200px;
      .right {
        ion-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  .left,
  .top {
    button {
      display: block;
      border-radius: 5px;
      margin: 5px 0;
      background: var(--off-white);
      color: var(--secondary);
      padding: 10px;
      font-size: 14px;
      font-family: var(--title-font);
      box-shadow: 1px 1px 5px rgba(0, 0, 0, .25);
    }
  }
  .top {
    button {
      width: 100%;
      font-size: 12px;
    }
  }
  .bottom,
  .right {
    ion-icon {
      transition: 0.3s;
      font-size: 30px;
    }
  }
}

.test-button-circle {
  background: var(--surface);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 15px;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  color: var(--title);
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
  ion-icon {
    font-size: 30px;
  }
}
