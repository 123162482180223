:root {
  @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 2560px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi),
  screen
  and (min-device-width: 1200px)
  and (max-device-width: 2560px)
  and (-webkit-min-device-pixel-ratio: 1) {
    ion-content {
      --offset-bottom: auto;
      //--overflow: hidden;
      overflow: auto;
      background: var(--bg);
      &::-webkit-scrollbar {
        display: none;
      }
    }

    ion-router-outlet#main {
      width: var(--screen-width);
      position: absolute;
      margin: 0 auto;
      box-shadow: 0 0 25px rgba(0, 0, 0, .25);
    }
    ion-app {
      background: var(--input-background);
    }
  }
}
