.page-title {
  color: var(--title);
  text-align: center;
  font-family: var(--title-font);
  font-size: var(--xl-text);
  &.left {
    text-align: left
  }
}

.page-subtitle {
  text-align: center;
  padding: 0 15px;
  color: var(--body);
  font-size: var(--large-text);
  &.left {
    text-align: left
  }
}

.page-description {
  text-align: left;
  color: var(--body);
  font-size: var(--base-text);
}

.input {
  font-size: var(--large-text);
  border: none;
  border-bottom: 1px solid var(--body);
  width: calc(var(--screen-width) * 0.8);
  &::placeholder {
    color: var(--body);
  }
}
.input-label {
  font-family: var(--title-font);
  padding-top: 5px;
  color: var(--body);
  font-size: var(--small-text);
}
