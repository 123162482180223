.state-input-page {
  --background: var(--bg);

  ion-item.state-2-ion-input {
    padding: 0;
    background: rgba(0,0,0,0);
  }

  .state-2-back-arrow{
    font-size: 2.3rem;
    color: var(--white);
    z-index: 2;
    position: absolute;
    left: 5%;
    margin-top: 1rem;
  }

  .state-2-header{
    margin-top: 10vh;
    padding: 5vh 5% 3vh 5%;
    .state-2-title {
      color: var(--off-white);
      font-family: var(--title-font);
      font-size: 3.2rem;
      @media(max-width: 375px) {
          font-size: 24px;
      }
    }
  }

  .state-2-content {
    text-align: center;
    ion-label.stacked-label{
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      font-family: var(--sub-title-font);
      font-style: italic;
    }

    ion-fab-button {
      --transition: 0.3s;
      --background-activated: var(--white);
      --background: rgba(255,255,255,1);
      --color: var(--secondary);
      --color-activated: var(--secondary);
    }

    .disabled ion-fab-button {
      --background: var(--label);
    }

    .state-2-link {
      a {
        font-family: var(--title-font);
        font-size: 14px;
        text-decoration: underline;
        color: var(--label);
      }
    }

    .message-error {
      color: var(--label);
      font-size: 1.2em;
      margin-top: 5px;
      margin-left: 0;
    }

    .state-2-form {
      .label-ios + ion-input .text-input, .label-ios + ion-textarea .text-input, .label-ios + .input + .cloned-input {
        margin: 0;
      }
      ion-item {
        --background: var(--black);
        --placeholder-color: var(--white);
        --padding-start: 0;
        --inner-border-width: 0;
      }
      ion-label {

      }
      ion-input {
        --placeholder-opacity: 1;
      }
    }

    .profile-photo-plate {
      display: inline-block;
      background: var(--off-white);
      border-radius: 50%;
      height: 95px;
      width: 95px;
      box-shadow: 0px 0px 30px rgba(0,0,0,0.5);
    }

    .profile-photo-button {
      padding: 12px;
      font-size: 70px;
      background: rgba(255,255,255,0);
      color: var(--primary);
    }

    .italic-text {
      font-size: 16px;
      font-family: var(--sub-title-font);
      font-style: italic;
      color: rgba(255,255,255,0.75);
      padding: 0 0 30px 0;
    }

    .skip-button {
      font-size: 18px;
      padding: 25px 0;
      color: var(--white);
    }

    padding: 0 5%;
    .clear-select {
      --border-width: 0 0 1px 0;
      --border-color: rgba(255, 255, 255, .75);
      margin: 2rem auto 0rem auto;
      --background: transparent;
      --color: rgba(255,255,255,0.7);
      padding-left: 0;
      .select-ios .select-icon .select-icon-inner, .select-md .select-icon .select-icon-inner{//select triangle
        color: rgba(255,255,255,0.7);
        left: 0;
        border-top: 0.6rem solid;
        border-right: 0.6rem solid transparent;
        border-left: 0.6rem solid transparent;
        top:40%;
      }

    }

    ion-select.state-2-select {
      max-width: 100%;
      padding: 0;
      color: white;
      .select-placeholder {
        color: white;
      }
      .select-icon {
        .select-icon-inner {
          color: white;
          left: 0;
          top: 100%;
        }
      }
    }

    .state-2-input{
      width: 100%;
      background: transparent;
      border: none;
      border-bottom: 1px solid rgba(255,255,255,0.7);
      color: white;
      font-size: 2.7rem;

      ion-input,
      .profile-name-input,
      .profile-email-input,
      .profile-password-input {
        margin: 0;
      }
    }

    .state-2-datetime {
      padding: 0;
    }

    input::placeholder {
      color: rgba(255,255,255,0.9);
    }

    .sub-text{
      margin-top: 0.3rem;
      color: rgba(255,255,255,0.7);
      &.underline{
        text-decoration: underline;
        text-decoration-color: rgba(255,255,255,0.7);
      }
      &.large{
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
      }
    }

    .bottom-position {
      background: rgba(0,0,0,0);
      transition: 0.3s;
      position: absolute;
      bottom: 16px;
      right: 16px;
      z-index: 25;
    }
  }
}

