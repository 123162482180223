:root {
  body.dark {
    --black: #FCFCFC;
    --title: #EFF2F6;
    --body: #D9DEE9;
    --label: #A0A8BD;
    --placeholder: #6E7A91;
    --line: #4B5266;
    --input-background: #313746;
    --surface: #242A39;
    --bg: #141B2A;
    --off-white: #080E1B;
    --white: #080E1B;
    --backround: var(--bg);

    --surface-opacity-80: rgba(36, 42, 57, .8);

    --ion-text-color: #FFFFFF;

    --accent-1: #346C60;
    --accent-2: #8A5169;
    --accent-3: #3D7788;
    --accent-4: #536287;
    --accent-5: #796A4C;

    --success: #3DC7AA;
    --success-dark-3: #77D8C3;
    --success-dark-2: #A7EADB;
    --success-dark-1: #143E35;
    --success-light-3: #2EAB91;
    --success-light-2: #00876B;
    --success-light-1: #176353;
    --success-background: #EDF9F7;
    --success-gradient: linear-gradient(114.44deg, #31936A 0%, #318897 100%);

    --error: #E85B96;
    --error-dark-3: #FF9BC5;
    --error-dark-2: #FFCDE2;
    --error-dark-1: #FEEFF5;
    --error-light-3: #D3417F;
    --error-light-2: #BA1359;
    --error-light-1: #4A2635;
    --error-gradient: linear-gradient(113.42deg, #AB4462 0%, #A24884 98.35%);

    --primary: #3CB6D7;
    --primary-dark-3: #68CEEC;
    --primary-dark-2: #BBEBF9;
    --primary-dark-1: #E5F6FB;
    --primary-light-3: #1B8DAD;
    --primary-light-2: #106C86;
    --primary-light-1: #004659;
    --primary-gradient: linear-gradient(114.44deg, #3BA6BF 0%, #2E84A8 100%);
    --primary-gradient-disabled: linear-gradient(114.44deg, #28465B 0%, #2B4E61 100%);

    --secondary: #6287E4;
    --secondary-dark-3: #91ABEC;
    --secondary-dark-2: #B9CBF9;
    --secondary-dark-1: #E5EBFB;
    --secondary-light-3: #4465B8;
    --secondary-light-2: #103184;
    --secondary-light-1: #2A3B67;
    --secondary-gradient: linear-gradient(114.44deg, rgb(60, 140, 199) 0%, rgb(102, 90, 178) 100%);
    --secondary-gradient-disabled: linear-gradient(140.73deg, rgb(36, 72, 104) 0%, rgb(73, 67, 129) 100%);

    --tertiary: #F4B740;
    --tertiary-dark-3: #ECC169;
    --tertiary-dark-2: #B87B00;
    --tertiary-dark-1: #3A301B;
    --tertiary-light-3: #BE820B;
    --tertiary-light-2: #9C6901;
    --tertiary-light-1: #573A00;
    --tertiary-gradient: linear-gradient(95.73deg, #D18F4E 4.56%, #CDA843 95.44%);
    --tertiary-gradient-disabled: linear-gradient(95.73deg, #684D38 4.56%, #665936 95.44%);

    --neutral-shadow: rgba(0, 0, 0, .1);
    --primary-shadow: black;
    --tertiary-shadow: black;

    --ion-color-primary: var(--primary);
    --ion-color-secondary: var(--secondary);

    /** Exceptional variable TO BE REFACTORED SOON **/
    --default-title: #141B2A;
  }
}

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  // ****************
  //    New colors
  // ****************
  --black: #080E1B;
  --title: #141B2A;
  --body: #4B5266;
  --label: #6E7A91;
  --placeholder: #A0A8BD;
  --line: #D9DEE9;
  --input-background: #EFF2F6;
  --backround: var(--bg);
  --surface: #FFFFFF;
  --bg: #F7F8FC;
  --off-white: #FCFCFC;
  --white: #FFFFFF;

  --surface-opacity-80: rgba(255, 255, 255, .8);

  --ion-text-color: #141B2A;

  --success: #3DC7AA;
  --success-dark-3: #2EAB91;
  --success-dark-2: #00876B;
  --success-dark-1: #176353;
  --success-light-3: #77D8C3;
  --success-light-2: #A7EADB;
  --success-light-1: #E6FBF6;
  --success-background: #EDF9F7;
  --success-gradient: linear-gradient(114.44deg, #3DC785 0%, #3DB6C7 100%);

  --error: #E85B96;
  --error-dark-3: #D3417F;
  --error-dark-2: #BA1359;
  --error-dark-1: #4A2635;
  --error-light-3: #FF9BC5;
  --error-light-2: #FFCDE2;
  --error-light-1: #FEEFF5;
  --error-background: #FEEFF5;
  --error-gradient: linear-gradient(113.42deg, #ED5579 0%, #DF5CAB 98.35%);

  --primary: #3CB6D7;
  --primary-dark-3: #1B8DAD;
  --primary-dark-2: #106C86;
  --primary-dark-1: #004659;
  --primary-light-3: #68CEEC;
  --primary-light-2: #BBEBF9;
  --primary-light-1: #E5F6FB;
  --primary-gradient: linear-gradient(114.44deg, #349DC8 0%, #46CCE6 100%);
  --primary-gradient-disabled: linear-gradient(114.44deg, #ACD3E3 0%, #B9DEE5 100%);

  --secondary: #6287E4;
  --secondary-dark-3: #4465B8;
  --secondary-dark-2: #103184;
  --secondary-dark-1: #2A3B67;
  --secondary-light-3: #91ABEC;
  --secondary-light-2: #B9CBF9;
  --secondary-light-1: #E5EBFB;
  --secondary-gradient-opacity-80: linear-gradient(114.44deg, rgba(60, 140, 199, 0.8) 0%, rgba(102, 90, 178, 0.8) 100%);
  --secondary-gradient: linear-gradient(114.44deg, #43A5EC 0%, #8A7AEB 100%);
  --secondary-gradient-disabled: linear-gradient(140.73deg, #A9D6F6 0%, #C0B8F3 100%);

  --tertiary: #F4B740;
  --tertiary-dark-3: #BE820B;
  --tertiary-dark-2: #9C6901;
  --tertiary-dark-1: #573A00;
  --tertiary-light-3: #ECC169;
  --tertiary-light-2: #B87B00;
  --tertiary-light-1: #FBF3E5;
  --tertiary-gradient: linear-gradient(95.73deg, #FA9D43 4.56%, #EFC042 95.44%);
  --tertiary-gradient-disabled: linear-gradient(95.73deg, #F6CFA9 4.56%, #F3E3B8 95.44%);

  --accent-1: #77D8C3;
  --accent-2: #FF9BC5;
  --accent-3: #68CEEC;
  --accent-4: #91ABEC;
  --accent-5: #D09522;

  --neutral-shadow: rgba(20, 27, 42, .1);
  --primary-shadow: var(--primary-dark-2);
  --tertiary-shadow: var(--tertiary-dark-2);

  --request-logs: #7952B3;
  --state-macgine-logs: #4B6587;
  --navigation-logs: #77D970;
  --error-logs: #FF0000;

  --ion-color-primary: var(--primary);
  --ion-color-secondary: var(--secondary);

  /** Exceptional variable TO BE REFACTORED SOON **/
  --default-title: #141B2A;

  /** custom variables **/
  
  --form-page-background: url('/start/assets/img/backgrounds/bg-turquoise.svg') no-repeat center center / cover;
  --disabled-gradient-vertical: linear-gradient(#9BA4B3, #859CA1);
  
  --presentation-page-background: var(--form-page-background);

  --disabled-gradient-vertical: linear-gradient(#9BA4B3, #859CA1);

  --facebook-color: #3b5998;
  --twitter-color: #38A1F3;
  --google-color: #ea4335;

  /* levels and rewards */
  --level-circle-border-size: 6px;
  --level-circle-size: 65px;
  --level-circle-font-size: 34px;
  --level-circle-upcoming-color: white;
  --level-circle-upcoming-background: rgba(103, 144, 218, 0.6);
  --level-circle-upcoming-border: #819edf;
  --level-circle-pending-color: #C6CEDE;
  --level-circle-pending-background: #DCE1EC;
  --level-circle-complete-color: gold;
  --level-circle-complete-background: white;
  /* end levels and rewards */

  --disabled-gradient: linear-gradient(to left, #BEBEBE, #CFCFCF);
  --tabs-color: white;
  --tabs-text-color: #839AA6;
  --tabs-active-color: var(--ion-color-primary);

  //fonts
  --title-font-heavy: 'Lato-Black';
  --title-font: 'Lato-Semibold';
  --sub-title-font: 'Lato-Italic';
  --body-font: 'Lato-Regular';

  // font sizes
  --over-9000: 32px;
  --xl-text: 28px;
  --huge-text: 24px;
  --larger-text: 20px;
  --large-text: 18px;
  --base-text: 16px;
  --medium-text: 14px;
  --small-text: 13px;
  --tiny-text: 11px;

  // Buttons
  --button-height: 56px;

  // exceptions where variables contain same color in both modes
  --neutral-0-black: #080E1B;
  --neutral-1-title: #080E1B;
  --neutral-2-body: #141B2A;
  --neutral-3-label: #242A39;
  --neutral-4-placeholder: #313746;
  --neutral-5-line: #4B5266;
  --neutral-6-input-background: #6E7A91;
  --neutral-7-surface: #A0A8BD;
  --neutral-8-bg: #D9DEE9;
  --neutral-9-off-white: #EFF2F6;
  --neutral-10-white: #FCFCFC;

  --ion-color-primary: var(--primary);

  // Ionicons
  // --------------------------------------------------
  // The premium icon font for Ionic. For more info, please see:
  // http://ionicframework.com/docs/v2/ionicons/

  --ionicons-font-path: "assets/fonts";

  --app-logo-height: 170px;
  --tabs-md-tab-text-capitalization: uppercase;
  --tabs-md-tab-font-weight: 500;
  --tabs-md-tab-text-transform: scale(1);

  //does nothing for ios
  --tabs-ios-tab-text-capitalization: uppercase;
  --tabs-ios-tab-font-weight: 500;
  --tabs-ios-tab-text-transform: scale(1);

  --break-very-small: 320px;//iPhone 5
  --break-small: 375px;//iPhone 6
  --break-large: 600px;//iPhone 6Plus and Nexus 6P
  --break-very-large: 750px;
  --break-extra-large: 900px;

  --nexus-5x: 56/100;
  --iphone-4: 66/100;
  //$break-very-small-height: 480px;//iPhone 4
  --break-very-small-height: 568px;//iPhone 5
  --break-small-height: 640px;//Nexus 5
  --break-big-height: 700px;//Nexus 6P

  --levels-progress-meter-height: 12px;
  --status-bar-height: var(--ion-safe-area-top, 0px);

  --screen-width: 100vw;
  --screen-height: 100vh;

  --popup-width: calc(var(--screen-width) * 0.85);

  @media screen
  and (min-device-width: 1200px)
  and (max-device-width: 2560px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi),
  screen
  and (min-device-width: 1200px)
  and (max-device-width: 2560px)
  and (-webkit-min-device-pixel-ratio: 1) {
    //--screen-width: 390px;
  }
}
.ios {
  --toolbar-height: 54px;
  --tab-bar-height: 54px;
}

.md {
  --toolbar-height: 54px;
  --tab-bar-height: 0;
}
