.title-font-heavy {
  font-family: var(--title-font-heavy);
}

.title-font {
  font-family: var(--title-font);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.lato {
  &.black {
    font-family: var(--title-font-black);
  }
  &.semibold {
    font-family: var(--title-font);
  }
  &.regular {
    font-family: var(--body-font);
  }
  &.italic {
    font-family: var(--sub-title-font);
  }
}
