  .upselling-popup {
    --width: var(--screen-width);
    --width: 100vh;

    .popover-content,
    .popover-viewport {
      top: 50px !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    .swiper-wrapper {
      //height: 200px;
    }

    .modal-wrapper {
      height: 100vh;
    }

    .swiper-pagination-bullets {
      bottom: 15px;
    }

    .upselling-title {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 15px;
      transform: translateX(-50%);
      font-size: 24px;
      color: white;
      font-family: var(--title-font);
      @media(max-width: 320px) {
        font-size: 20px;
      }
    }

    .upselling-close {
      position: absolute;
      z-index: 10;
      top: 10px;
      right: 10px;
      font-size: 24px;

      .ios-close {
        border-radius: 50%;
        padding: 5px;
        background: rgba(0, 0, 0, .25);
        color: white;
      }
    }

    .upselling-swiper-slider {
      --bullet-background: rgba(0, 0, 0, 0.5);
      --bullet-background-active: white;

      ion-slide.slide-one,
      ion-slide.secondary-gradient {
        background: var(--secondary-gradient);
      }

      ion-slide.slide-two,
      ion-slide.primary-gradient {
        background: var(--primary-gradient);
      }

      ion-slide.slide-three,
      ion-slide.error-gradient {
        background: var(--error-gradient);
      }

      ion-slide.slide-four,
      ion-slide.tertiary-gradient {
        background: var(--tertiary-gradient);
      }

      ion-slide.slide-five,
      ion-slide.success-gradient {
        background: var(--success-gradient);
      }
    }

    .upselling-options-section {
      display: grid;
      grid-auto-rows: minmax(100px, auto);

      .option-one {
        grid-row: 1;
        grid-column: 1;
        border-bottom: 1px solid var(--line);
        border-right: 1px solid var(--line);
      }

      .option-two {
        grid-row: 1;
        grid-column: 2;
        border-bottom: 1px solid var(--line);
      }

      .option-three {
        grid-row: 2;
        grid-column: 1;
        border-bottom: 1px solid var(--line);
        border-right: 1px solid var(--line);
      }

      .option-four {
        grid-row: 2;
        grid-column: 2;
        border-bottom: 1px solid var(--line);
      }

      //display: flex;
      //flex-direction: row;
      //border-bottom: 1px solid rgba(0,0,0,.25);
      .upselling-option.three-months {
        border-left: 1px solid rgba(0, 0, 0, .25);
        border-right: 1px solid rgba(0, 0, 0, .25);
      }

      .upselling-option.enabled {
        .border-container {
          border: 3px solid var(--secondary);
          color: var(--secondary);
          background: var(--secondary-light-1);

          .info-option-container {
            .month-number {
              color: var(--secondary-dark-3);
            }
            .full-price {
              color: var(--secondary);
            }
          }
        }
      }

      .upselling-option {
        transition: 0.3s;
        box-sizing: border-box;
        position: relative;
        flex: 1;

        .border-container {
          background: var(--surface);
          display: flex;
          height: 100%;
          transition: 0.3s;
          border: 3px solid transparent;

          .info-option-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 6;
            padding-left: 15px;

            .month-number {
              font-weight: 600;
              font-family: var(--title-font-heavy);
              color: var(--title);
              font-size: var(--large-text);
              @media(max-width: 320px) {
                font-size: var(--base-text);
              }
            }

            .month-pay {
              font-size: var(--small-text);
              color: var(--label);
            }

            .full-price {
              white-space: nowrap;
              color: var(--body);
              font-size: var(--large-text);
              @media(max-width: 320px) {
                font-size: var(--base-text);
              }

              &:after {
                content: '.';
                visibility: hidden;
              }

              &.show-price {
                bottom: 0;
                opacity: 1;
              }

            }
          }

          .discount-container {
            flex: 4;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .cloud-container {
      text-align: center;
      padding-top: 25px;
      @media(max-width: 320px) {
        padding-top: 0;
      }

      .cloud-icon {
        position: relative;
        top: 30px;
        color: var(--body);
        font-size: 70px;
        margin-bottom: 20px;
        @media(max-width: 320px) {
          font-size: 45px;
          top: 20px;
          margin-bottom: 10px;
        }
      }

      .cloud-title {
        font-size: 18px;
        color: var(--title);
        font-weight: 600;
        padding: 0 15px;
        @media(max-width: 320px) {
          font-size: 18px;
        }
      }

      .cloud-subtitle {
        font-size: 16px;
        color: var(--body);
        padding-top: 5px;
        @media(max-width: 320px) {
          font-size: 14px;
        }
      }
    }


    .discount-plate {
      color: white;
      flex-direction: column;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 5px;
      width: 50px;
      height: 50px;
      background: var(--tertiary-gradient);

      .discount-save {
        font-weight: 600;
      }

      .discount {
        font-family: var(--title-font);
        font-size: 16px;
      }
    }

    .billing-section {
      color: var(--body);
      text-align: center;

      .billing-header {
        text-decoration: underline;
        padding: 5px 0;
        font-size: 14px;
        font-weight: 600;
        @media(max-width: 320px) {
          font-size: 10px;
        }
      }

      .billing-text {
        font-size: 13px;
        margin-bottom: 90px;
        padding: 0 5px;
        @media(max-width: 320px) {
          font-size: 8px;
        }
      }
    }

    .upselling-button-section {
      position: absolute;
      width: 100%;
      bottom: 0;
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        bottom: 20px;
      }

      .continue-margin {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .no-capitalize {
        text-transform: none;
      }
    }

  }

  //iPhone X
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .continue-button {
      margin-bottom: 40px;
    }
  }

  //iPhone XR
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    .continue-button {
      margin-bottom: 40px;
    }
  }

  //iPhone XS
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .continue-button {
      margin-bottom: 40px;
    }
  }

  //iPhone XS Max
  @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    .continue-button {
      margin-bottom: 40px;
    }
  }
