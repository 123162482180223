.fade-in-animation {
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  &.delay-1sec {
    opacity: 0;
    animation-delay: 1s;
  }
  &.delay-2sec {
    opacity: 0;
    animation-delay: 2s;
  }
}

.fade-out-animation {
  animation-name: fadeOut;
  animation-delay: 0s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: 1000;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% {
    opacity: 1;
    z-index: 1000;
  }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@keyframes blinking {
  //0% { opacity: 0; }
  //25% { opacity: 1; }
  //50% { opacity: 1; }
  //75% { opacity: 1; }
  //100% { opacity: 0; }
}

.pulse-box {
  position: relative;
  .pulse {
    background: var(--tertiary);
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    opacity: 0;
  }
  :nth-child(2) {
    animation-delay: 0.5s;
  }
  :nth-child(3) {
    animation-delay: 1s;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
