.advanced-gradient { background: var(--primary-gradient); }
.link { color: var(--primary); }

.primary-color { color: var(--primary); }
.primary-gradient-bg { background: var(--primary-gradient); }

.error-bg { background: var(--error-background); }
.error-color { color: var(--error) !important; }
.error-light-1-bg { background: var(--error-light-1); }
.error-light-2-bg { background: var(--error-light-2); }
.error-light-3-bg { background: var(--error-light-3); }
.error-dark-1-color { color: var(--error-dark-1); }
.error-dark-2-color { color: var(--error-dark-2); }
.error-dark-3-color { color: var(--error-dark-3); }
.error-gradient-bg { background: var(--error-gradient); }

.tertiary-color { color: var(--tertiary); }
.tertiary-light-1-bg { background: var(--tertiary-light-1); }
.tertiary-light-2-bg { background: var(--tertiary-light-2); }
.tertiary-light-3-bg { background: var(--tertiary-light-3); }
.tertiary-dark-1-color { color: var(--tertiary-dark-1) }
.tertiary-dark-2-color { color: var(--tertiary-dark-2) }
.tertiary-dark-3-color { color: var(--tertiary-dark-3) }
.tertiary-light-1-color { color: var(--tertiary-light-1); }

.success-color { color: var(--success); }

.secondary-color { color: var(--secondary); }
.secondary-gradient { background: var(--secondary-gradient); }
.secondary-light-1-bg { background: var(--secondary-light-1); }
.secondary-light-1-color { color: var(--secondary-light-1); }
.secondary-dark-2-color { color: var(--secondary-dark-2); }
.secondary-dark-3-color { color: var(--secondary-dark-3); }


.white-color { color: white; }
.title-color { color: var(--title); }
.label-color { color: var(--label); }
.description-color,
.body-color { color: var(--body); }
.placeholder-color { color: var(--placeholder); }

.blue { --background: var(--secondary-light-1); background: var(--secondary-light-1); color: var(--secondary-dark-3); }
.yellow { --background: var(--tertiary-light-1); background: var(--tertiary-light-1); color: var(--tertiary-dark-3); }
.red { --background: var(--error-light-1); background: var(--error-light-1); color: var(--error-dark-3); }
.green { --background: var(--success-light-1); background: var(--success-light-1); color: var(--success-dark-3); }

.blue-dark { --background: var(--secondary-light-1); background: var(--secondary-light-1); color: var(--secondary-dark-3); }
.yellow-dark { --background: var(--tertiary-dark-1); background: var(--tertiary-dark-1); color: var(--tertiary-dark-3); }
.red-dark { --background: var(--error-dark-1); background: var(--error-dark-1); color: var(--error-light-3); }
.green-dark { --background: var(--success-dark-1); background: var(--success-dark-1); color: var(--success-light-3) }


.green-highlight {
  padding: 3px 5px;
  border-radius: 5px;
  color: var(--success-dark-2);
  background: var(--success-light-1);
}

.off-white { color: var(--off-white); }
.black { color: var(--black) };

.secondary-gradient { background: var(--secondary-gradient); }
.primary-gradient { background: var(--primary-gradient); }
.error-gradient { background: var(--error-gradient); }
.tertiary-gradient { background: var(--tertiary-gradient); }
.success-gradient { background: var(--success-gradient); }

.text-error-important { color: var(--error) !important }