@mixin placeholder($color){
  .text-input::-moz-placeholder {
    color: var(--colors-#{$color});
  }

  .text-input:-ms-input-placeholder {
    color: var(--colors-#{$color});
  }

  .text-input::-webkit-input-placeholder {
    color: var(--colors-#{$color});
  }
}

.back-arrow-style {
  min-width: 18px;
  font-size: 3rem;
  color: white;
  z-index: 2;
  &.dark{
    color: var(--label);
  }
}

.back-arrow-position {
  @extend .back-arrow-style;
  position: absolute;
  margin-top: 1rem;
  &.right{
    right: 5%;
    margin-top: 1.5rem;
  }
  &.left{
    left: 4%;
  }
}

//buttons
.btn{
  font-weight: bold;
  opacity: 1;
  bottom: 0;
  text-transform: none;
  text-align: center;
  border-radius: 35px;
  font-family: var(--title-font);
}

.btn-large-action {
  @extend .btn;
  font-size: var(--large-text);
  line-height: 56px;
  height: var(--button-height);
  width: calc(var(--screen-width) * 0.9);
  margin: 2% calc(var(--screen-width) * 0.05);
}

.btn-medium-action{
  @extend .btn;
  font-size: calc(var(--screen-width) * 0.045);
  line-height: calc(var(--screen-width) * 0.12);
  height: calc(var(--screen-width) * 0.12);
  width: 74%;
  margin: 2% 13%;
  &.long{
    width: 86%;
    margin: 2% 7%;
  }
}

.turquoise-gradient-btn,
.primary-gradient-btn {
  background: var(--primary-gradient);
  --background: var(--primary-gradient);
  color: white;
  --color: white;
}

.advanced-gradient-btn {
  background: var(--primary-gradient);
  color: white;
}
.bonus-gradient-btn {
  background: var(--error-gradient);
  --background: var(--error-gradient);
  color: white;
  --color: white;
}

.turquoise-btn-shadow {
  --box-shadow: none;
  box-shadow: 4px 7px 25px -14px var(--primary-shadow);
}

.coral-btn-shadow{
  --box-shadow:none;
  box-shadow: 4px 7px 25px -14px var(--error);
}

.disabled-btn {
  background: var(--disabled-gradient);
  --background: var(--disabled-gradient);
  color: white;
  --color: white;
  box-shadow: none;
}

.ionic-button {
  height: 56px;
  font-size: var(--large-text);
  background: none;
  --box-shadow: none;
  border-radius: 50%;
  text-transform: none;
  font-family: var(--title-font);
  width: 90%;
  &.turquoise-gradient-btn,
  &.primary-gradient-btn{
    --background-activated: var(--primary-gradient-disabled);
  }
  &.tertiary-gradient-btn {
    --background-activated: var(--tertiary-gradient-disabled);
  }
}

.support-action {
  background: transparent;
  text-align: center;
  color: var(--body);
  font-size: calc(var(--screen-width) * 0.047);
  font-weight: bold;
  &.large{
    font-size: 1.9rem;
    color: var(--secondary-dark-3);
  }
}

