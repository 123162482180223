:root {
  --img-bottom: 30%;
  --button-height: 3em;
  --bullet-margin: 23px;

  .pagination-bullet {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
    background: var(--black);
    opacity: .2;
    margin: 5px;
  }
  
  .pagination-bullet-active {
    opacity: .8;
  }

  // Onboarding swiper
  .onboarding-swiper {
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      .pagination-bullet { 
        background: white; 
      }
    }
    .swiper-wrapper {
      div.swiper-slide {
        font-size: var(--xl-text);
        text-shadow: 1px 1px 3px black;
        font-family: var(--title-font);
        color: white;
      }
    }
  }

  // Opening page swiper
  .opening-swiper {
    .swiper-pagination {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // Sign up flow swiper
  .sign-up-swiper {
    .swiper-wrapper {
      height: auto;
    }
    .sign-up-slide {
      align-items: baseline;
      text-align: left;
      .slide-container {
        width: var(--screen-width);
        margin: 0 25px;
        .slide-title {
          font-family: var(--title-font);
          color: var(--title);
          @media(max-width: 374px) { font-size: 24px; }
          font-size: 32px;
          margin: 0 20px 25px 20px;
          white-space: nowrap;
          &.slide-title-row {
            display: inline-flex;
            align-items: baseline;
          }
          .short-date-input {
            border-bottom: 1px solid black;
            width: 40px;
            --padding-bottom: 0px;
            --padding-end: 0;
            margin: 0 5px;
            text-align: center;
          }
        }
        .slide-text-input {
          .slide-input {
            border: none;
            border-bottom: 1px solid var(--body);
            margin: 0 20px;
            width: calc(100% - 40px);
            &::placeholder {
              color: var(--body);
            }
          }
        }
        .slide-input-description {
          margin: 5px 20px;
          color: var(--body);
          font-size: 13px;
          &.no-margin {
            margin: 0;
          }
        }
        .email-form {
          ion-icon {
            position: absolute;
            right: 0;
          }
          &.empty {
            .email-status-icon,
            .slide-input-description {
              color: var(--body);
            }
          }
          &.unconfirmed {
            .email-status-icon,
            .slide-input-description {
              color: var(--error);
            }
          }
          &.confirmed {
            .email-status-icon,
            .slide-input-description {
              color: var(--success);
            }
          }
        }
      }
    }
    div.swiper-slide {
      width: var(--screen-width);
    }
  }

  // Upselling swiper
  .upselling-swiper {
    .swiper-pagination {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      .pagination-bullet {
        width: 8px;
        height: 8px;
      }
    }
    .swiper-wrapper {
      div.swiper-slide {
        flex-wrap: wrap;
        transition: 0.3s;
        padding-top: 60px;
        padding-bottom: 40px;
        max-height: max-content;

        img {
          width: 250px;
          @media(max-width: 320px) {
            width: 200px;
          }
        }

        .slide-text {
          color: white;
          width: 100%;

          .slide-title {
            font-weight: bold;
            font-size: 18px;
          }

          .slide-subtitle {
            font-size: 14px;
          }
        }
      }
    }
  }

  // Explanation page swiper
  .explanation-swiper {
    overflow: visible;
    .swiper-pagination {
      position: absolute;
      bottom: -10vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
    }
    .swiper-wrapper {
      margin-top: 20vh;
      div.swiper-slide {
        width: 100%;
        height: 80%;
        text-align: center;
        position: relative;

        .pagination-bullet {
          background: var(--title);
          margin: 0 8px;
          width: 10px;
          height: 10px;
          opacity: 0.5;
          @media only screen and (max-device-width: 360px) {
            margin: 0 6px;
          }
        }
  
        .pagination-bullet-active {
          background: var(--secondary);
          opacity: 1;
        }

        .slide-title {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: var(--title-font);
          font-size: var(--over-9000);
          @media(max-width: 320px) {
            font-size: 22px;
          }
          max-width: 85%;
        }

        .slide-content {
          .slide-icon {
            max-height: 250px;
            max-width: 95%;
          }
        }

        .slide-title {
          color: var(--title);
        }

        .slide-description {
          color: var(--body);
          font-size: var(--medium-text);
        }

        .slide-description {
          margin: 10px 0;
          font-size: 18px;
          max-width: 85%;
          @media(max-width: 375px) {
            font-size: 18px;
          }
          @media(max-width: 320px) {
            font-size: 14px;
          }
        }

        .slide-footer {
          position: absolute;
          bottom: 10%;
          color: white;
          width: 100%;

          .footer-text {
            width: 100%;
            font-family: var(--sub-title-font);
            font-style: italic;
            font-size: 1.7rem;
            margin-bottom: 15px;
          }

          .btn-adjustment {
            margin-bottom: -0.4rem;
          }
        }
      }
    }
  }

  // Instagram swiper
  .instagram-swiper {
    padding-top: 50px;
    background: var(--bg);
    width: var(--screen-width);
    height: 100vh;
    .swiper-pagination {
      text-align: center;
      position: absolute;
      bottom: 15px;
      width: var(--screen-width);
    }
    .swiper-wrapper {
      div.swiper-slide {
        img {
          max-height: 60vh;
        }
        display: inline;
        .media-info{
          color: var(--white);
          text-align: left;
          margin: calc(var(--bullet-margin) * 2) 20px;
          .media-caption{
            max-height: 15vh;
            font-size:18px;
            color: var(--body);
            margin-bottom: 1em;
            overflow: scroll;
          }
          .media-date{
            font-size: 14px;
            color: var(--label);
          }
        }
      }
    }
  }

}