.instructions-container {
  position: fixed;
  transition: 0.3s;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 25;
  color: white;
  border-radius: 15px 15px 0 0;
  background: var(--primary-gradient);
  box-shadow: 0px -5px 12px rgba(0,0,0,.45);
  @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    padding-bottom: 20px;
  }
  &.hidden {
    bottom: -200px;
  }
  .instructions-header {
    display: flex;
    align-items: center;
    padding: 10px;
    .instructions-add {
      font-size: 22px;
    }
    .instructions-label {
      font-size: 14px;
      font-family: var(--sub-title-font);
      padding-left: 10px;
      font-style: italic;
    }
  }
  .instructions-slots {
    display: flex;
    font-weight: 600;
    &.scheduled {
      justify-content: center;
    }
    .slot.pass-button,
    .slot.add-button {
      align-items: center;
      justify-content: center;
      font-family: var(--title-font);
      font-size: 20px;
      &.scheduled {
        white-space: nowrap;
        padding: 0 15px;
      }
    }
    .slot.pass-button {
      background-color: rgba(0,0,0,0);
      color: white;
    }
    .slot.add-button {
      color: var(--primary);
      .button-text {
        font-family: var(--title-font);
      }
    }
    .slot.add-button.checkmarked {
      background: rgba(0,0,0,0);
    }
    .slot {
      flex-basis: 50%;
      position: relative;
      background: white;
      height: 50px;
      margin: 0 10px 10px 10px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      &.empty {
        background: rgba(255,255,255,0.25);
        border: 1px dashed white;
        .slot-avatar,
        .slot-name,
        .slot-close {
          color: var(--off-white);
          display: none;
        }
      }
      .slot-avatar {
        flex: 0 0 50px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          padding: 2px;
        }
      }
      .slot-name {
        color: black;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        margin: 8px;
        font-weight: bold;
      }
      .slot-close {
        color: var(--off-white);
        position: absolute;
        right: 0;
        ion-icon {
          font-size: 22px;
          background: var(--title);
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}

.hidden-instructions {
  bottom: -300px;
}
