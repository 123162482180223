:root {

  .popup::part(content) {
    overflow: visible;
  }

  .popup {
    --border-radius: 5px;
    --width: 315px;

    ion-backdrop {
      transition: 0.3s;
    }

    .popover-viewport {
      display: block;
      box-shadow: none;
      background: var(--surface);
      text-align: center;
      // top: 20% !important;
      overflow: unset;
      font-family: var(--body-font);

      .close-icon {
        float: right;
        color: white;
        font-size: 30px;
        background: rgba(0,0,0,0.25);
        height: 25px;
        width: 25px;
        border-radius: 50%;
        margin-top: 5px;
      }

      .header {
        font-family: var(--title-font);
        color: var(--title);
        text-align: center;

        .sub-header {
          font-family: var(--title-font);
          color: white;
          font-size: var(--huge-text);
          padding: 60px 10px 30px 10px;
          border-top-left-radius: var(--border-radius);
          border-top-right-radius: var(--border-radius);
          line-height: 1.2em;
          @media(max-width: 320px) {
            font-size: 22px;
          }
          &.disabled{
            --background: var(--disabled-gradient-vertical);
          }
          &.blue {
            background: var(--secondary-gradient);
          }
        }
      }

      .content {
        background: var(--surface);
        text-align: center;
        color: var(--body);
        padding: 1vh 0;
        padding-bottom: 15px;
        margin: auto;
        font-family: var(--body-font);
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
        .body-image {
          img {
            width: 75%;
          }
        }
        .title{
          font-family: var(--sub-title-font);
          font-style: italic;
          font-size: 22px;
          color: var(--title);
          margin: 10px 10px 0 10px;
          @media(max-width: 320px) {
            font-size: 19px;
          }
        }

        .description {
          font-size: var(--base-text);
          padding: 10px 0;
          line-height: 1.4em;
          margin: 10px 15px 0 15px;
        }

        .line-divider{
          margin: 2vh auto;
          width: 60%;
          height: 1px;
          background: grey;
          opacity: 0.4;
        }

        .note{
          font-size: var(--base-text);
          margin: 0.5rem auto;
          line-height: 2rem;
        }

        ion-button {
          //text-transform: capitalize;
          font-weight: bold;
          font-family: var(--title-font);
          margin: 1vh auto;
          font-size: var(--large-text);
          &.primary-button {
            color: white;
          }
          &.small{
            font-size: var(--base-text);
          }
          &.text-white {
            color: white;
          }
        }
      }

      .container {
        position: absolute;
        //left: calc(var(--screen-width) * 0.075 - 5px) !important;
        top: calc(var(--screen-height) * -0.3) !important;
        width: 100%;
        text-align: center;
        box-shadow: 0px 10px 70px 2px var(--neutral-shadow);

        .main-icon {
          background: white;
          border-radius: 50%;
          border: 5px solid white;
          box-shadow: 0px 10px 70px 2px var(--neutral-shadow);
          width: 80px;
          height: 80px;
          position: absolute;
          left: 50%;
          top: -40px;
          transform: translateX(-50%);
          &.img{
            padding: 3px;
          }
          &.support{
            color: var(--secondary);
          }
          ion-icon.bigger-icon{
            transform: scale(2.5);
          }
        }
      }
    }
  }

  .popup.edit-popup {
    // display: block;
    // height: max-content;
    // box-shadow: none;
    // width: var(--screen-width);
    // background: var(--surface);
    text-align: center;
    top: -20% !important;
    // overflow: unset;
    font-family: var(--body-font);
    // z-index: 20003;
    ion-label {
      --color: #387ef5;
    }
    ion-item {
      --background: var(--surface);
      --border-color: #387ef5;
      --inner-border-width: 0 0 2px 0;
    }
    ion-item.gender-form {
      margin-left: -5px;
    }
    ion-datetime {
      padding-bottom: 6px;
    }
    .validation-error{
      color: var(--color-danger);
    }
    .popover-content,
    .popover-viewport{
      padding: 0;
      //background: var(--surface) !important;
      ion-button {
        --color: #728E9A;
        --background: var(--surface);
        --border-radius: 0;
        width: 50%;
        margin: 0;
        border-top: 1px solid #dbdbdf;
        border-right: 0.55px solid #dbdbdf;
        text-transform: none;
      }
      ion-button.update {
        --color: #00BCD4;
        font-weight: bold;
        border-right: none;
      }
    }
  }

  .low-top {
    top: -25% !important;
  }

  .secondary-popup-button {
    font-size: var(--large-text);
    // color: var(--body);
  }

  .primary-popup-button {
    height: 50px;
    line-height: 50px;
    font-size: var(--large-text);
    width: 75%;
    &.tight {
      height: 42px;
      line-height: 42px;
    }
    @media(max-width: 320px) {
      height: 44px;
      line-height: 44px;
      font-size: 15px;
      width: 75%;
    }
  }

  .feedback-popover {
    z-index: 31000 !important;
    //--width: 85%;
    form {
      background: var(--off-white);
      border-radius: 5px;
    }
    .profile-popover-title {
      text-align: center;
      font-size: var(--huge-text);
      color: var(--title);
      padding: 25px 0 15px 0;
    }

    .profile-popover-input {
      width: 80%;
      margin: 0 auto;
      border: 1px solid rgba(0,0,0,.2);
      margin-bottom: 15px;
      font-size: var(--base-text);
      border-radius: 5px;
      input {
        padding: 8px;
      }
    }

    .profile-popover-button-group {
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px;
      justify-content: center;
      border-top: 1px solid rgba(0,0,0,.1);
      ion-button {
        margin: 0;
        flex-grow: 1;
      }
      .profile-cancel-button {
        border-right: 1px solid rgba(0,0,0,.1);
        color: var(--label);
      }
      .profile-ok-button {
        font-weight: bold;
      }
    }
  }

  .popover-title {
    width: 100%;
    text-align: center;
    font-size: var(--huge-text);
    color: var(--title);
    padding: 10px 0;
  }


  .decline-menu-popover {
    --width: 250px;
  }

  .matches-options-popover {
    --width: 42%;
  }

  //@media screen
  //and (min-device-width: 1200px)
  //and (max-device-width: 1600px)
  //and (-webkit-min-device-pixel-ratio: 2)
  //and (min-resolution: 192dpi),
  //screen
  //and (min-device-width: 1200px)
  //and (max-device-width: 1600px)
  //and (-webkit-min-device-pixel-ratio: 1) {
  //  .popup {
  //    .popover-content,
  //    .popover-viewport {
  //      width: calc(var(--screen-width) - 30px);
  //      .header {
  //        .sub-header {
  //          padding: 60px 25px 25px 25px;
  //        }
  //      }
  //    }
  //  }
  //}
}
