//This deals with the styling of pages like 'FAQ' and 'Personality-Types'

.informative-header{
    ion-navbar{
         box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
        -moz-box-shadow:0 0 8px 0 0px 4px 8px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow:0 0 8px 0 0px 4px 8px 0px rgba(0,0,0,0.2);
    }
}

.informative-page{
    .top-content{
        position: relative;
        text-align: center;
        color: var(--body);
        background-color: var(--surface);
        padding: 7% 3%;
        line-height: 1.4;
        font-size: 1.7rem;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
        -moz-box-shadow:0 0 8px 0 0px 4px 8px 0px rgba(0,0,0,0.2);
        -webkit-box-shadow:0 0 8px 0 0px 4px 8px 0px rgba(0,0,0,0.2);
        .header-title{
            text-align: center;
            font-size: 3rem;
            color: var(--title);
        }
        .divide{
            margin-bottom: 1rem;
        }
        img{
            padding-bottom: 3%;
            width: 50%;
            margin: auto;
        }
    }
    .line-divide{
      width: 10%;
      height: 3px;
      background-color: var(--line);
      margin: 4% 45%;
    }

    .content-holder{
      background-color: var(--surface);
      z-index: -1;
      position: relative;
      .header-title{
        text-align: center;
        padding-top: 7%;
        font-size: 2.7rem;
        margin-bottom: 7%;
        color: var(--title);
      }
      .content{
        color: var(--body);
        margin: 2rem 2rem;
        line-height: 1.4;
        font-size: 1.5rem;
      }
      .content-last{//for faq
        @extend .content;
        margin-bottom: 0;
        padding-bottom: 2.5rem;
      }
  }
}

.informative-footer{
    width: 100%;
    background-color: var(--surface);
    text-align: center;
    padding: 3rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .label{
        font-size: 1.7rem;
        font-weight: 600;
        color: var(--body);
    }
}
