.bold { font-weight: bold; }
.underlined { text-decoration: underline; }
.italic { font-style: italic; }
.uppercase { text-transform: uppercase; }

.heavy { font-family: var(--title-font-heavy); }
.semibold { font-family: var(--title-font); }
.regular { font-family: var(--body-font); }

.xl-text { font-size: var(--xl-text); }
.huge-text { font-size: var(--huge-text); }
.larger-text { font-size: var(--larger-text); }
.large-text { font-size: var(--large-text); }
.base-text  { font-size: var(--base-text); }
.medium-text { font-size: var(--medium-text); }
.small-text { font-size: var(--small-text); }
.tiny-text  { font-size: var(--tiny-text); }

.heading {
  color: var(--label);
  font-family: var(--title-font-heavy);
  font-size: var(--medium-text);
  text-transform: uppercase;
}

.help-text {
  color: var(--label);
  font-family: var(--body-font);
  font-size: var(--small-text);
}

.h1 {
  font-size: 32px;
}

.top-indent { margin-top: 15px; }
.bottom-indent { margin-bottom: 15px; }

.sub-title-font {
  font-family: var(--sub-title-font);
  font-style: italic;
}


