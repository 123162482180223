.state-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pre-action-title, .action-title {
    font-style: normal;
    font-weight: 500;
    @media screen and (max-height: 568px) {
      font-size: 15px;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .state-back-arrow {
     margin: 1rem auto auto 4%;
     min-width: 18px;
     font-size: 3.4rem;
   }

   .state-header{
     max-width: 85%;
     margin: auto;
     text-align: center;
     .state-title{
       max-width: 90%;
       color: var(--title);
       font-size: 30px;
       font-family: var(--title-font);
       margin: auto;
       margin-bottom: 3vh;
       //margin-top: calc(13vh - 23px - 1rem);//23px and 1rem account for the back arrow
       @media screen and (max-height: 568px)   {
         //margin-top: calc(9vh - 23px - 1rem);//23px and 1rem account for the back arrow
         font-size: 24px;
       }
     }
     .state-sub-title, .pre-action-title, .action-title{
       font-size: 20px;
       @media screen and (max-height:568px)   {
         font-size: 15px;
       }
       @media(max-width: 375px) {
         font-size: 16px;
       }
       @media(max-width: 320px) {
         font-size: 14px;
       }
     }
   }

    .support-action {
      span.details {
        font-style: normal;
        font-weight: 500;
      }
    }
  }

.pre-action-title,
.action-title {
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.2rem;
  @media screen and (max-height: 568px)   {
    font-size: 15px;
  }
}

.support-action {
  span.details {
    font-style: normal;
    font-weight: 500;
  }
}

.state-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  #main-img {
    height: 35vh;
    margin-top: 2vh;
  }

  p.sub-image-text {
    text-align: center;
    margin: 5px 0 0 0;
    font-size: 15px;
    font-weight: 600;
    color: var(--title);
  }

  .state-icon-medium{
    width: calc(var(--screen-width) * 0.55);
    font-size: calc(var(--screen-width) * 0.35);;
  }
  .pending-header {
    font-style: italic;
    font-size: 16px;
  }
}

.state-footer {
  padding-bottom: 1rem;
  width: 100%;
  text-align: center;
  position: relative;
  flex-grow: 1;
  .state-sub-description {
    font-family: var(--title-font);
    margin-bottom: 20px;
    font-size: 1.8rem;
    @media(max-width: 320px) {
        font-size: 1.4rem;
    }
  }
  &.center-item{
    align-items: center;
    display: flex;
  }
  &.two-buttons{
    display: flex;
    align-items: flex-end;
    .button-wrapper{
        width: 100%;
        margin-bottom: 1vh;
    }
  }

  .state-footer-wrapper {
    width: 100%;
    text-align: center;
  }
  .state-footer-note{
    opacity: 0.8;
    font-size: 16px;
    margin: 1vh;
    font-weight: 600;
    color: var(--title);
  }
  .state-description{
    position: absolute;
    top: 5%;
    font-size: calc(var(--screen-width) * 0.05);;
    width: 100%;
  }
  .warning {
    background: var(--bg);
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: left;
    padding: 12px 10px;
    border-top: 3px solid var(--line);
    margin-top: 10px;
    .left {
      float: left;
      width: 6%;
      display: block;
      ion-icon {
        font-size: 14px;
        margin-top: 2px;
      }
    }
    .right {
      width: 94%;
      float: right;
      display: block;
      font-size: 12px;
    }
  }
}

.state-color {
  .state-header,
  .state-footer {
    color: var(--off-white);
  }
}

.state-page.after-onboarding-steps {
  .state-content {
    //margin: 25px 0 0 0;
  }

  .state-title {
    color: var(--title);
    font-family: var(--title-font);
    font-size: 26px;
    line-height: 1.2;
    margin: 0 5%;
    margin-top: 10px;
    @media(max-width: 320px) {
      font-size: 22px;
    }
  }
  .state-sub-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    margin: 15px 8px 0 8px;
  }
  .state-header {
    flex-grow: 1;
  }
}

.privacy-notice {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 20px 0;
  padding: 0 15px;
  opacity: 0.75;
  font-size: 12px;
  font-family: var(--title-font);
  .privacy-notice-eye {
    display: inline-block;
    padding: 0 10px;
  }
  .privacy-notice-text {
    margin-top: 10px;
  }
  ion-icon {
    font-size: 18px;
  }
}
