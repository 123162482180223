// http://ionicframework.com/docs/theming/

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import "theme/typography";
@import "theme/colors";
@import "theme/desktop";
@import "theme/mixins.scss";
@import "theme/functions";

@import 'theme/directives/tooltip.scss';
@import 'theme/tooltips.scss';
@import 'theme/alert.scss';
@import 'theme/variables.scss';
@import 'theme/forms.scss';
@import 'theme/popup.scss';
@import 'theme/popovers.scss';
@import 'theme/instructions.scss';
@import 'theme/upselling-popup.scss';
@import 'theme/informative_page.scss';
@import 'theme/state_styling/state_page.scss';
@import 'theme/state_styling/state_page_input.scss';
@import 'theme/svg.scss';
@import "theme/font-rules.scss";
@import "theme/testing-tools.scss";

@import "theme/new-form.scss";
@import "theme/animations.scss";
@import "theme/list-styles.scss";
@import "theme/white-page.scss";
@import "theme/modals.scss";

@import "theme/swiper.scss";

//NEW
html { 
  font-size: 62.5%; 
  line-height: 1.35;
}

* {
  font-family: var(--body-font);
}

ion-content {
  --background: var(--bg);
}

ion-toggle {
  --background-checked: var(--primary);
}

ion-datetime {
  --background: var(--bg);
}

ion-label {
  font-family: var(--body-title) !important;
}

.ios {
  ion-modal {
    --ion-background-color-rgb: 247, 248, 252;
    --ion-color-step-150: var(--surface);
  }
  body.dark {
    ion-modal {
      --ion-background-color-rgb: 20, 27, 42;
    }
  }
}


img, svg {
  display: inline-block;
}

// .swiper-pagination-bullet-active {
//   background: white;
// }

// .swiper-pagination-bulletґ {
//   background: rgba(255,255,255,.5);
// }

//

@function test() {

}

//

.desktop-header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 20vh;
  background: var(--line);
}

.ion-content-extended-padding {
  --padding-end: 25px;
  --padding-start: 25px;
  --padding-top: 25px;
  --padding-bottom: 25px;
}

ion-content {
  &.padding-20 {
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
}

.loading-wrapper {
  &.sc-ion-loading-ios,
  &.sc-ion-loading-md {
    background: var(--surface);
    color: var(--title);
  }
}


.dark {
  .picker-wrapper {
    background: var(--surface);

    .picker-above-highlight {
      background: linear-gradient(to bottom, var(--surface), 80%, transparent);
    }
    .picker-below-highlight {
      background: linear-gradient(to top, var(--surface), 80%, transparent);
    }
  }
}

.popover-info {
  --background: var(--surface);
  --color: var(--body);
}

.send-button {
  position: fixed;
  z-index: 10000000;
  right: 15px;
  top: calc(var(--toolbar-height) + 30px);
  background: var(--surface);
  color: var(--title);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 30px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,.25);
}

.md, .ios {
  .header-md::after, .tabs-md[tabsPlacement="top"] > .tabbar::after,
   .footer-md::before, .tabs-md[tabsPlacement="bottom"] > .tabbar::before{
    background-image: none;
  }
}

.ios {
  .modal-card {
    --backdrop-opacity: 0.3;
    .modal-wrapper {
      width: var(--screen-width);
      height: var(--screen-height);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
}

.full-height-modal {
  .modal-wrapper {
    height: var(--screen-height);
  }
}

.margin-left-15 {
  margin-left: 15px;
}

.video-iframe {
  border-radius: 5px;
  border: none;
}

ion-slides {
  width: 100%;
  height: 100%;
  display: block;
}

.no-pointer-events {
  pointer-events: none;
}

.no-margin {
  margin: 0;
}

ion-content[fullscreen] {
  &.vertically-centered, .vertically-centered {
    --toolbar-height: 0px;
  }
}

ion-button.use-the-app-button {
  //position: relative;
  //right: -10px;
  --padding-end: 0;
  --padding-start: 0;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 20px var(--surface);
}

html.plt-cordova.ios {
  ion-content {
    //top: 20px;
  }
}

.leave-tribe-alert {
  @media(max-width: 320px) {
    .alert-wrapper {
      margin-top: -250px;
    }
  }
}

::shadow .range-slider {
  pointer-events: none !important;
}

::shadow .range-knob-handle {
  pointer-events: auto;
}



.status-closed {background: var(--error) }
.status-open { background: var(--success) }
.status-available { background: var(--tertiary) }
.status-invisible { background: var(--line) }

.status-bullet {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.blocked {
  pointer-events: none;
}

ion-range {
  --bar-background: var(--line);
  --bar-background-active: var(--primary);
  --bar-height: 4px;
  --knob-background: var(--primary);
  --knob-size: 3em;
}

.reversed-range {
  --bar-background: var(--primary);
  --bar-background-active: var(--line);
}

.regular-alert {
  .alert-wrapper {
    .alert-button-group {
      .alert-button {
        text-transform: none;
      }
    }
  }
}

.translate-centered {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.spinner-container {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  background: white;
  border-radius: 50%;
  padding: 5px;
  transform: translateX(-50%);
  left: 50%;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.25);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  &.shown {
    opacity: 1;
    top: -45px;
  }
  &.top.shown {
    opacity: 1;
    top: 60px;
  }
}

.page-back-arrow {
  position: fixed;
  top: 15px;
  left: 15px;
  font-size: 30px;
  z-index: 100;
}

.tertiary-page {
  ion-toolbar {
    --border-width: 0 !important;
    ion-buttons {
      ion-back-button {
        color: var(--body);
      }
    }
  }
}

ion-button.golden-button {
  --background-activated: var(--tertiary);
}

.disabled-button {
  --background: var(--primary-gradient-disabled);
  background: var(--primary-gradient-disabled);
  color: rgba(255,255,255, .35);
  pointer-events: none;
  &.black-shadow {
    box-shadow: 4px 7px 25px -14px black;
  }
  &.golden-button {
    --background: var(--tertiary-gradient-disabled);
  }
}

.medium-button {
  height: var(--button-height);
}

.secondary-gradient {
  background: var(--secondary-gradient);
}

.half-opacity {
  opacity: 0.5;
}

.blur-light {
  filter: blur(3px);
}

.blur {
  filter: blur(6px);
}

.medium-blur {
  filter: blur(10px);
}

.heavy-blur {
  filter: blur(15px);
}

.alert-input-wrapper {
  color: black;
}

$start: 1;
$end: 5;
@for $i from $start through $end {
  .min-height-#{ $i } {
    --min-height: calc(#{ $i } * 50px);
  }
}

.popover-arrow::after {
  background: var(--surface) !important;
}

.z-index-30000 {
  z-index: 30000 !important;
}

.z-index-35000 {
  z-index: 35000 !important;
}

.z-index-50000 {
  z-index: 50000 !important;
}

.menu-popover {
  $start: 1;
  $end: 4;
  --min-width: 250px;
  @for $i from 1 to 6 {
    &.min-height-#{ $i } {
      --min-height: calc(#{ $i } * 44px);
    }
  }
  z-index: 26000 !important;
  .popover-content,
  .popover-viewport {
    border-radius: 5px;
    overflow: unset;
  }
  &.fixed {
    .popover-viewport {
      width: 250px;
      position: fixed;
      bottom: 6%;
      left: 15%;
    }
  }
}

.info-popover {
  --min-width: 225px;
}

.default-image{
  background: url('./assets/img/default-avatar.png');
  background-size: contain;
}

.vertically-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  --additionnal-margin: 0px;
  margin-top: var(--toolbar-height);
  min-height: calc(100% - var(--toolbar-height) - var(--additionnal-margin));

  .top {
    width: 100%;
    text-align: center;
    flex-grow: 1;
  }
  .middle {
    width: 100%;
    text-align: center;
    flex-grow: 1;
  }
  .bottom {
    width: 100%;
    text-align: center;
    flex-grow: 1;
  }
}

.ion-page.transparent-header {
  ion-toolbar {
    --color: inherit;
    --background: transparent;
    --ion-color-base: transparent !important;
  }
  .header:after {
    background-image: none;
  }
}

ion-list.list-md,
ion-list.list-ios {
  --background: var(--bg);
  background: var(--bg);
}

ion-select-option {
  --color: var(--body);
}

ion-item {
  --background: var(--bg);
}

ion-toolbar {
  --background: var(--surface);
  --color: var(--title);
  --min-height: var(--toolbar-height);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.01);
  ion-buttons {
    ion-back-button {
      --color: var(--body);
    }
  }
  ion-title {
    font-family: var(--title-font);
    font-weight: normal;
    font-size: 20px;
  }
}

ion-tab-bar {
  ion-tab-button.tab-selected {
    font-weight: 800;
  }
}

.hide-button {
  opacity: 0;
  transform: scale(0);
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.golden-button {
  --background: var(--tertiary-gradient);
  color: white;
}

.heading-label{
  padding: 1rem 1.4rem;
}

.offline-tag {
  position: relative;
  z-index: 100;
  opacity: 0;
  transition: 0.3s;
  margin-top: 3px;
  margin-left: 15px;
  font-family: var(--title-font);
  font-weight: bold;
  padding: 2px 10px;
  padding-top: 4px;
  font-size: 12px;
  border-radius: 7px;
  color: white;
  background: var(--placeholder);
}

.update-message-margin {
  margin-top: 30px;
}

.secondary-button {
  color: var(--primary);
  font-family: var(--title-font);
  font-size: 16px;
  padding: 10px 15px;
}

ion-content[fullscreen] {
  position: absolute;/*Make it go behind the header*/
}

.form-page {
  ion-content {
    --background: var(--form-page-background);
  }
}

.ion-color-white {
  --ion-color-base: #FFF;
  --ion-color-base-rgb: 255,255,255;
  --ion-color-contrast: #000;
  --ion-color-contrast-rgb: 0,0,0;
  --ion-color-shade: #FFF;
  --ion-color-tint: #FFF;
}

.ion-color-gray {
  --ion-color-base: #839AA6;
  --ion-color-base-rgb: 131, 154, 166;
  --ion-color-contrast: #FFF;
  --ion-color-contrast-rgb: 255,255,255;
  --ion-color-shade: #839AA6;
  --ion-color-tint: #FFF;
}
//END NEW


//sets the font for everything in the app
html, ion-app.md, ion-app.ios {
  font-family: var(--body-font);
  font-size: 20px;
}
html, ion-app.md, ion-app.ios {
  font-family: var(--body-font) !important;
  font-size: 10px !important;
}

*:focus {
  outline: none;
}

.centered {
  text-align: center;
}
@mixin skeleton-text {
  border-radius: 10px;
  background: lightgray;
  color: rgba(0, 0, 0, 0);
  height: 14px;
  margin: 3px;
  display: inline-block;
}

.custom-toast {
  --background: var(--white);
  --color: var(--title);
  --width: calc(var(--screen-width) * 0.95);
}

.custom-loading {
  --background: var(--surface);
  color: var(--title);
}

ion-app {
  .profile-img-status {
    position: absolute;
    margin: 8% 3rem 8% 3rem;
    background-color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 30px;
    .check {
      margin: -0.6rem -0.2rem;
      font-size: 4rem;
      color: #69C9B5;
    }
  }



  .profile-img {
    display: block;
    margin: auto;
    background-color: var(--surface);
    width: calc(var(--screen-width) * 0.27);
    height: calc(var(--screen-width) * 0.27);
    border-radius: 8rem;
    border: 4px solid var(--surface);
    box-shadow: 0px 0px 30px -2px  var(--neutral-shadow);
  }

  .twitter-colored {
    ion-icon {
      color: var(--twitter-color);
      --color: var(--twitter-color);
    }
  }
  .google-colored {
    ion-icon {
      color: var(--google-color);
      --color: var(--google-color);
    }
  }
  .facebook-colored {
    ion-icon {
      color: var(--facebook-color);
      --color: var(--facebook-color);
    }
  }
  .normal-colored {
    ion-icon {
      color: var(--primary);
      --color: var(--primary);
    }
  }
  .writing-colored {
    ion-icon {
      color: var(--title);
      --color: var(--title);
    }
  }

  .toast-container {
    .toast-button {
      color: var(--primary);
      font-weight: bold;
    }
  }

  .text-center {
    text-align: center;
  }

  .emoji-button{
    margin: -3px 0rem 5px -5px;
  }

  .animated {
    &.bounce {
      transition-property: all;
      transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
      transition-duration: .5s;
      opacity: 0;
      transform: scale(0,0);
      &.show {
        opacity: 1;
        transform: scale(1,1);
      }
    }
  }
}

.balance-holder-position{
  position: absolute;
  right: 3%;
  margin-top: 4%;
  z-index: 2;
}

.empty-button {
  background: rgba(0,0,0,0);
  --background: rgba(0,0,0,0);
  --background-activated: rgba(0,0,0,0);
  --box-shadow: none;
  border-radius: 50px;
  font-family: var(--title-font);
  font-size: var(--large-text);
  text-transform: none;
  width: 100%;
  border: 1px solid var(--body);
  color: var(--body);
  transition: 0.3s;
  text-align: center;
  margin: 10px 0;
}

.no-border {
  --border-width: 0 !important;
  border: none !important;
}


/*form fixes*/
.item-label-floating {
  .input-wrapper {
    .label-md, .label-ios{
      margin: 0;
      color: #999;
    }
  }
}

ion-bottom-sheet {
  &.no-search {
    #title-button {
      margin-top: 15px !important;
      margin-bottom: -10px !important;;
    }
  }
  #ibs-container {
    background: var(--surface) !important;
    #ibs-header {
      text-align: center;
      color: var(--title);
      padding-left: 12px;
      padding-right: 12px;
      margin-top: -12px;
      #drag-icon {
        margin: 5px auto;
      }
      #title {
        display: none;
        position: relative;
        left: 0;
      }
    }
    ion-radio {
      margin-right: 15px;
    }
    ion-label {
      font-family: var(--body-font);
    }
  }

}

ion-searchbar {
  --background: var(--input-background) !important;
  .searchbar-input-container {
    .searchbar-clear-button {
      //display: none !important;
    }
  }
}

.modal-close-button {
  margin-bottom: 0px;
  color: #95a4b8;
  font-size: 35px;
}

.shadow-top {
  box-shadow: 0px 1px 10px rgb(0 0 0 / 25%)
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1000;
  font-size: 35px;
  color: var(--label);
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-icon-absolute {
  position: absolute;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  font-size: 25px;
  padding: 5px;
  color: var(--white);
  background: var(--placeholder);
  border-radius: 50%;
  &.no-plate {
    color: var(--title);
    background: transparent;
    border: none;
    border-radius: 0;
  }
  &.right {
    right: 15px;
  }
  &.left {
    left: 15px;
  }
}

.simple-notification img {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
}

scroll-content{
  overflow-y:auto !important;
}
.app-logo {
  max-height: var(--app-logo-height);
}
.lbl-size{
  font-size:2rem;
}

img.disabled{
  opacity: .7;
}

ion-item.disabled {
  opacity: .5;
}

button.disabled {
  background: lightgray;
}

.btn.disabled-text {
  opacity: .5;
}

.content-md, .content-ios{
  color: #A7B7BE;
}
.tabs-md[tabsHighlight=true] .tab-highlight {
  background: #57EBFF;
}

.menu_header{
	height: 170px;
    position: relative;
	.user-details{
	    background-position: center;
    	height: 170px;
    	text-align: center;
	    -webkit-filter: blur(3px);
	}
	.profile{
      height: 70px;
	    width: 70px;
	    border-radius: 50%;
	    display: block;
	    vertical-align: middle;
	    margin: auto;
	    position: absolute;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    top: 0;
	}
}

.app-menu-item{
	position: relative;
	.app-icon{
	    font-size: 2em;
	    margin-left: 10px;
	    position: absolute;
	    left: 0;
	    z-index: 2;
	    top: 25px;
	}
	.app-details{
    	display: inline-block;
	    position: absolute;
	    z-index: 2;
	    left: 15%;
	    width: 85%;
	    border-bottom: 1px solid #ddd;
	    padding-bottom: 10px;
	}
}
.app-menu-item-one{
	position: relative;
	.app-icon{
	    font-size: 2em;
	    margin-left: 10px;
	    position: absolute;
	    left: 0;
	    z-index: 2;
	    top: 25px;
	}
	.app-details{
    	display: inline-block;
	    position: absolute;
	    z-index: 2;
	    left: 15%;
	    width: 85%;
	    border-bottom: 1px solid #ddd;
	    padding-bottom: 10px;
	}
}
.app-menuicon{
    position: absolute;
    top: 25px;
}
.app-menutitle{
    display: block;
    font-weight: 700;
    color: #999;
    margin-left: 15%;
    cursor: pointer;
}
.menu-description{
    margin-left: 15%;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

ion-header::after, [tabsPlacement="top"] > ion-tabbar::after {
  background-image: none;
}

/**THIS IS FOR THE HEADER OF TRIBE CHAT AND TRIBE HIGHLIGHT**/
.nav-bar{
    padding: 0rem;
    height: 56px;
    align-items: center ;
    .nav-title{
        color: white;
        z-index: -1;
        .toolbar-title{
            //word-wrap: break-word;
            //white-space: initial;
        }
        .toolbar-title-md{
            padding: 0;
        }
        .toolbar-title-ios{
          width: 50%;
          margin-left: auto;
          margin-right: auto;
        }
    }
    .nav-title, .nav-title-md{
        padding: 0 0.7rem 0 0;
    }
    .nav-icon-left{
        width: 24px;
        color: white;
        padding-left: -0.5rem;
    }
    .nav-icon-right{
        width: 24px;
        color: white;
        //margin-right: 100%;
    }

    .nav-content{//shit magoo
        margin-top: 0rem;
    }

    &.full-page, .navTitle, .toolbar-title-md {
      padding: 0 12px;
    }
}

.pac-container.pac-logo.hdpi {
  .pac-item {
    font-family: var(--sub-title-font);
    font-style: italic;
  }
}

.pac-container {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border-top: none;
  margin-top: 15px;
  left: calc(50% - calc(var(--screen-width) / 2) + 15px) !important;
  width: var(--screen-width) !important;
  .pac-item {
    border-top: none;
    font-size: 18px;
    padding: 5px 0;
    .pac-icon.pac-icon-marker {
      color: var(--title);
      font-size: 18px;
    }
    span {
      font-size: 18px;
      color: var(--body);
    }
    span:not([class]){
      color: var(--line);
    }
  }
}

.pac-item:hover {
  background: rgba(0,0,0,0);
}

.pac-container:after{
  content: none;
}

.capitalize {
  text-transform: capitalize;
}

.rotated--45 {
  transform: rotate(-45deg);
}

.blue-tooltip {
  white-space: nowrap;
  background: var(--secondary-gradient);
  opacity: 0;
  padding: 7px 12px;
  color: white;
  animation-name: fadeIn;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  border-radius: 5px;
  position: absolute;
  z-index: 100;
  font-family: var(--title-font);
  font-size: var(--base-text);
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.arrow-down,
.arrow-up {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.arrow-down {
  bottom: -8px;
  border-top: 10px solid #53a1f5;
}

.arrow-up {
  top: -4px;
  border-bottom: 5px solid #53a1f5;
}

.close-button,
.close-button-section {
  padding: 10px 0;
  margin-bottom: 5px;
  color: var(--placeholder);
  font-size: 30px;
}

.alert-button.cancel-alert-btn{
  span {
    color: var(--error);
  }
}

.custom-action-sheet {
  --background: var(--surface);
  --color: var(--title);
  --button-color: var(--body);
}

[aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md,
[aria-checked=true].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: var(--body);
}

.alert-message.sc-ion-alert-md,
.alert-input-group.sc-ion-alert-md {
  color: var(--body);
}

.alert-wrapper.sc-ion-alert-ios,
.alert-wrapper.sc-ion-alert-md{
  background: var(--surface);
  .alert-radio-label {
    color: var(--body);
  }
}


.modal-wrapper.sc-ion-modal-md,
.modal-shadow.sc-ion-modal-md {
  width: var(--screen-width);
  height: var(--screen-height);
}


.alert-follow-up{
  .alert-wrapper{
    background: white;
  }
  .alert-tappable {
    line-height: 1;
  }
  .alert-checkbox-button .alert-checkbox-label, .alert-radio-button .alert-radio-label{
    white-space: normal;
  }
  .alert-button-group{
    justify-content: space-around;
    button{
      text-transform: none;
      font-size: 16px;
    }
  }
}

.push-alert {
  --width: calc(var(--screen-width) * 0.75);
  --max-width: calc(var(--screen-width) * 0.75);
}

.green-color { color: var(--success); }

.red-color { color: var(--error); }

.message-options-popover::part(arrow)::after {
  background: var(--surface);
}
.message-options-popover {
  --width: max-content;
  &.wide {
    --width: 250px;
  }
}

.message-options-popover .popover-content,
.message-options-popover .popover-viewport {
  background: var(--surface);
  --width: max-content;
  box-shadow: 0px 0px 10px 4px rgba(0, 48, 64, 0.3);
}

.profile-options-popover .popover-content,
.profile-options-popover .popover-viewport {
  background: var(--surface);
  --width: 215px;
  box-shadow: 0px 0px 10px 4px rgba(0, 48, 64, 0.3);
}

.profile-options-popover-big .popover-content,
.profile-options-popover-big .popover-viewport {
  background: var(--surface);
  --width: 70%;
  box-shadow: 0px 0px 10px 4px rgba(0, 48, 64, 0.3);
}

.skeleton-text-container {
  border-radius: 15px;
  background: var(--surface);
  color: rgba(0, 0, 0, 0);
  padding: 0;
  width: max-content;
  margin: 10px 0;
  height: 14px;
}

.info-modal {
  .modal-wrapper {
    width: var(--screen-width);
    height: var(--screen-height);
  }
}

.modal-wrapper.sc-ion-modal-ios {
  width: var(--screen-width);
  height: var(--screen-height);
}

.expired {
  opacity: 0.5;
}

.close-cross {
  font-size: 32px;
  color: var(--placeholder);
}

.flex {
  display: flex;
}

.flex.flex-vertical-align {
  align-items: center;
}

.gap-5 {
  gap: 5px;
}

.relative-centered-horizontally {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
