.section-title {
  margin-top: 15px;
  margin-bottom: 3px;
  &.chat-title {
    margin-top: 0;
  }
}

.section-description {
  font-size: 13px;
}

.selection-description ~ toggle-option.option {
  margin-top: 0;
}
