.match-me-popover {
  --backdrop-opacity: 0;
  --box-shadow: 0 0 15px rgba(0,0,0,.25);
  top: 15px;
  .popover-arrow {
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,.25))
  }
  .popover-content,
  .popover-viewport {
    border-radius: 3px;
  }
}
